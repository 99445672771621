import { Injectable } from '@angular/core';
import { sortByDefault } from '@app/reducers/helpers/default-sorting.helper';
import { compose, Store } from '@ngrx/store';
import groupBy from 'lodash-es/groupBy';
import intersection from 'lodash-es/intersection';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { TreeviewItem } from '../../../shared/ngx-treeview/treeview-item';
import { getPermissionState, PermissionCheck, permissionDepartments } from '../../auth/permission.helper';
import { AppState } from '../../index';
import { getSelectedDepartmentIds } from '../../selected-departments/selected-departments.service';
import {
  convertArrayToObject,
  filterDeleted,
  mapAndSortEntities,
  mapEntities,
  mapEntity,
} from '../../shared/entity.helper';
import { DepartmentModel } from '../department/department.model';
import {
  getAuthenticatedDepartments,
  getDepartmentEntities,
  mapAndSortDepartments,
} from '../department/department.service';
import { ObjectMap } from '../index';
import { LocationAction } from './location.action';
import { LocationApi } from './location.api';
import { DepartmentOptionsPerLocation, LocationBaseModel, LocationModel, LocationState } from './location.model';

@Injectable()
export class LocationService {
  public constructor(
    private store: Store<AppState>,
    private api: LocationApi,
  ) {}

  public load() {
    this.store.dispatch(LocationAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(LocationAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(LocationAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(locationData: LocationBaseModel): Observable<any> {
    this.store.dispatch(LocationAction.add(locationData));

    return this.api.add(locationData).pipe(
      map((response) => {
        this.store.dispatch(LocationAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(LocationAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id: string, locationData: LocationBaseModel) {
    this.store.dispatch(LocationAction.update(id, locationData));

    return this.api.update(id, locationData).pipe(
      map((response) => {
        this.store.dispatch(LocationAction.updateSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(LocationAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public fetch(id: string) {
    this.store.dispatch(LocationAction.fetch(id));

    return this.api.fetch(id).pipe(
      map((response) => {
        this.store.dispatch(LocationAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(LocationAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public save(locationData: LocationBaseModel, id?: string) {
    if (id) {
      return this.update(id, locationData);
    }
    return this.add(locationData);
  }

  public deactivate(id: string) {
    this.store.dispatch(LocationAction.deactivate(id));

    return this.api.deactivate(id).pipe(
      tap(() => {
        this.store.dispatch(LocationAction.deactivateSuccess(id));
      }),
      catchError((response) => {
        this.store.dispatch(LocationAction.deactivateFailed(id));
        return observableThrowError(response);
      }),
    );
  }

  public activate(id: string) {
    this.store.dispatch(LocationAction.activate(id));

    return this.api.activate(id).pipe(
      tap(() => {
        this.store.dispatch(LocationAction.activateSuccess(id));
      }),
      catchError((response) => {
        this.store.dispatch(LocationAction.activateFailed(id));
        return observableThrowError(response);
      }),
    );
  }

  public location(id: string) {
    return this.store.select(getLocation(id));
  }
}

export const sortLocations = (locations: LocationModel[]) => sortByDefault<LocationModel>(locations);
export const mapAndSortLocations = mapAndSortEntities(sortLocations);

export const getLocationsState = (appState: AppState): LocationState => appState.orm.locations;

export const getLocationIds = compose((state) => state.items, getLocationsState);
export const getLocationEntities = createSelector(getLocationsState, (state) => state.itemsById);
export const getLocations = createSelector(getLocationIds, getLocationEntities, mapAndSortLocations);

export const getActiveLocations = createSelector(getLocations, (locations: LocationModel[]) =>
  filterDeleted(locations),
);
export const getActiveLocationsEnsureId = (mustHaveId?: string) =>
  createSelector(getLocations, (locations: LocationModel[]) => filterDeleted(locations, mustHaveId));

export const getLocation = (id: string) => createSelector(getLocationEntities, (entities) => mapEntity(id, entities));

export const getLocationsById = (ids: string[]) =>
  createSelector(getLocationEntities, (entities) => mapAndSortLocations(ids, entities));

export const getSelectedLocationsWithDepartments = createSelector(
  getSelectedDepartmentIds,
  getDepartmentEntities,
  getLocationEntities,
  (departmentIds, departmentsById, locationsById): DepartmentOptionsPerLocation[] => {
    const departments = mapAndSortDepartments(departmentIds, departmentsById);

    return getDepartmentOptionsPerLocation(departments, locationsById);
  },
);

export const getSelectedLocationsWithPermissionDepartments = (permissionCheck: PermissionCheck) =>
  createSelector(
    getSelectedDepartmentIds,
    getDepartmentEntities,
    getLocationEntities,
    getPermissionState,
    (departmentIds, departmentsById, locationsById, permissionState): DepartmentOptionsPerLocation[] => {
      // First get the IDs of the departments which you have the permission for.
      const permissionDepartmentIds = permissionDepartments(permissionCheck, permissionState);

      const permissionDepartmentEntities = convertArrayToObject(
        'id',
        Object.entries(departmentsById)
          .filter(([departmentId]) => permissionDepartmentIds.includes(departmentId))
          .map(([, department]) => department),
      );

      // Filter out the locations which should now be excluded because their departments were discarded.
      const filteredLocations = Object.values(locationsById)
        .map((location) => ({
          ...location,
          Department: intersection(location.Department, permissionDepartmentIds),
        }))
        .filter((location) => location.Department.length > 0);

      // Map to the format getDepartmentOptionsPerLocation expects.
      const departments = mapAndSortDepartments(permissionDepartmentIds, permissionDepartmentEntities);
      const locations = convertArrayToObject('id', filteredLocations);

      return getDepartmentOptionsPerLocation(departments, locations);
    },
  );

export const getDepartmentOptions = (departmentIds: string[]) =>
  createSelector(
    getDepartmentEntities,
    getLocationEntities,
    (departmentsById, locationsById): DepartmentOptionsPerLocation[] => {
      const departments = mapAndSortDepartments(departmentIds, departmentsById);
      return getDepartmentOptionsPerLocation(departments, locationsById);
    },
  );

export const getAuthenticatedLocationsWithDepartments = createSelector(
  getAuthenticatedDepartments,
  getLocationEntities,
  (departments, locationEntities) => getDepartmentOptionsPerLocation(departments, locationEntities),
);

export const getDepartmentOptionsPerLocation = (
  departments: DepartmentModel[],
  locationsById,
): DepartmentOptionsPerLocation[] => {
  const departmentsPerLocation = groupBy(departments, 'location_id');
  const locationIds = Object.keys(departmentsPerLocation);

  return mapAndSortLocations(locationIds, locationsById).map((value) => ({
    location: value,
    departments: departmentsPerLocation[value.id],
  }));
};

export const getDepartmentsWithLocations = createSelector(
  getSelectedDepartmentIds,
  getDepartmentEntities,
  getLocationEntities,
  (departmentIds, departmentsById, locationEntities): any[] => {
    const departments = mapAndSortDepartments(departmentIds, departmentsById);

    return departments.map((department: DepartmentModel) => ({
      ...department,
      location: locationEntities[department.location_id],
    }));
  },
);

interface AddTreeChildren {
  items: { [departmentId: string]: any };
  valueField: string;
  idField: string;
}

export const getTreeChildren = (objects: any[], valueField, idField) => {
  if (objects === undefined) {
    return [];
  }

  return objects.map(
    (object) =>
      new TreeviewItem({
        text: object[valueField],
        value: object[idField],
        collapsed: false,
        checked: false,
      }),
  );
};

export const getLocationTree = (
  departments: Partial<DepartmentModel[]>,
  locationsById: ObjectMap<LocationModel>,
  addChildren?: AddTreeChildren,
): TreeviewItem[] => {
  const departmentsPerLocation = groupBy(departments, 'location_id');
  const locationIds = Object.keys(departmentsPerLocation);

  return mapAndSortLocations(locationIds, locationsById).map((location: LocationModel) => {
    const departmentItems = departmentsPerLocation[location.id].map((department) => {
      const departmentItem = {
        text: department.name,
        value: department.id,
        collapsed: false,
        checked: false,
        children: undefined,
      };

      if (addChildren) {
        const children = getTreeChildren(addChildren.items[department.id], addChildren.valueField, addChildren.idField);
        departmentItem.children = children;
      }

      return new TreeviewItem(departmentItem);
    });

    return new TreeviewItem({
      text: location.name,
      value: location.id,
      classes: 'LocationService',
      collapsed: false,
      children: departmentItems,
    });
  });
};

export const getDepartmentsAfterPermissionCheckAsTreeview = (check: PermissionCheck) =>
  createSelector(
    getPermissionState,
    getDepartmentEntities,
    getLocationEntities,
    (permissionState, departmentEntities, locationsById) => {
      const departmentIds = permissionDepartments(check, permissionState);
      const departments: DepartmentModel[] = mapEntities(departmentIds, departmentEntities);
      return getLocationTree(departments, locationsById);
    },
  );

export const getFirstDepartmentId = createSelector(getAuthenticatedLocationsWithDepartments, (locations) => {
  if (locations && locations[0] && locations[0].departments && locations[0].departments[0]) {
    return locations[0].departments[0].id;
  }
});
