import { Injectable } from '@angular/core';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { DepartmentSchema } from '../../shared/schemas';

@Injectable()
export class DepartmentApi {
  private endpoint = 'departments/';

  public constructor(private gateway: ApiGateway) {}

  public add(data): Observable<any> {
    return this.gateway.post(this.endpoint, data).pipe(
      map((res) => reformatEntityResponse('Department', res)),
      map((data) => normalize(data, DepartmentSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public update(id, data, ignoreMetaMessage = false): Observable<any> {
    const params = { allow_deleted: '1' };
    return this.gateway.put(this.endpoint + id, data, { params, headers: { ignoreMetaMessage } }).pipe(
      map((res) => reformatEntityResponse('Department', res)),
      map((data) => normalize(data, DepartmentSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public batchUpdate(batch: any, ignoreMetaMessage = false) {
    return this.gateway.post(this.endpoint + 'batch', batch, { headers: { ignoreMetaMessage } }).pipe(
      map((res) => reformatListResponse('Department', res)),
      map((res) => normalize(res, arrayOf(DepartmentSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public fetch(id): Observable<any> {
    return this.gateway.get(this.endpoint + id, { params: 'allow_deleted=1' }).pipe(
      map((res) => reformatEntityResponse('Department', res)),
      map((data) => normalize(data, DepartmentSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public validateDeactivate(id: string, ignoreMetaMessage = true): Observable<any> {
    return this.gateway.delete(this.endpoint + id + '/validate', { headers: { ignoreMetaMessage } });
  }

  public deactivate(id: string, data?: { targetDepartmentId?: string; targetTeamId?: string }): Observable<any> {
    return this.gateway.delete(this.endpoint + id, undefined, undefined, data);
  }

  public activate(id): Observable<any> {
    return this.gateway.put(this.endpoint + id + '/activate', '');
  }
}
