import { Injectable } from '@angular/core';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { LocationSchema } from '../../shared/schemas';
import { LocationBaseModel } from './location.model';

@Injectable()
export class LocationApi {
  private endpoint = 'locations/';

  public constructor(private gateway: ApiGateway) {}

  public load(): Observable<any> {
    const params = {
      include: 'Department,Department.Team,Department.Shift,Department.Shift.Skill',
      allow_deleted: '1',
    };

    return this.gateway.get(this.endpoint, { params }).pipe(
      map((res) => reformatListResponse('Location', res)),
      map((data) => normalize(data, arrayOf(LocationSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public add(locationData: LocationBaseModel): Observable<any> {
    return this.gateway.post(this.endpoint, locationData).pipe(
      map((res) => reformatEntityResponse('Location', res)),
      map((data) => normalize(data, LocationSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public update(locationId: string, locationData: LocationBaseModel): Observable<any> {
    return this.gateway.put(this.endpoint + locationId, locationData, { params: 'allow_deleted=1' }).pipe(
      map((res) => reformatEntityResponse('Location', res)),
      map((data) => normalize(data, LocationSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public fetch(locationId: string): Observable<any> {
    return this.gateway.get(this.endpoint + '/' + locationId, { params: 'allow_deleted=1' }).pipe(
      map((res) => reformatEntityResponse('Location', res)),
      map((data) => normalize(data, LocationSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public deactivate(id: string): Observable<any> {
    return this.gateway.delete(this.endpoint + id);
  }

  public activate(id: string): Observable<any> {
    return this.gateway.put(this.endpoint + id + '/activate', '');
  }
}
