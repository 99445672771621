import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[Department]';

export const departmentActionType = {
  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  BATCH_UPDATE: `${ACTION_PREFIX} Batch Update`,
  BATCH_UPDATE_SUCCESS: `${ACTION_PREFIX} Batch Update Success`,
  BATCH_UPDATE_FAILED: `${ACTION_PREFIX} Batch Update Failed`,

  DEACTIVATE: `${ACTION_PREFIX} Deactivate`,
  DEACTIVATE_SUCCESS: `${ACTION_PREFIX} Deactivate Success`,
  DEACTIVATE_FAILED: `${ACTION_PREFIX} Deactivate Failed`,

  ACTIVATE: `${ACTION_PREFIX} Activate`,
  ACTIVATE_SUCCESS: `${ACTION_PREFIX} Activate Success`,
  ACTIVATE_FAILED: `${ACTION_PREFIX} Activate Failed`,
};

export class DepartmentAction {
  public static fetch(id: any): Action {
    return {
      type: departmentActionType.FETCH,
      payload: id,
    };
  }

  public static fetchSuccess(response): Action {
    return {
      type: departmentActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  public static fetchFailed(id: string, err): Action {
    return {
      type: departmentActionType.FETCH_SUCCESS,
      payload: {
        id,
        err,
      },
    };
  }

  public static add(data): Action {
    return {
      type: departmentActionType.ADD,
      payload: data,
    };
  }

  public static addSuccess(locationResponse): Action {
    return {
      type: departmentActionType.ADD_SUCCESS,
      payload: locationResponse,
    };
  }

  public static addFailed(err): Action {
    return {
      type: departmentActionType.ADD_FAILED,
      payload: err,
    };
  }

  public static update(id, data): Action {
    return {
      type: departmentActionType.UPDATE,
      payload: data,
    };
  }

  public static updateSuccess(response): Action {
    return {
      type: departmentActionType.UPDATE_SUCCESS,
      payload: response,
    };
  }

  public static updateFailed(err): Action {
    return {
      type: departmentActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  public static batchUpdate(batch): Action {
    return {
      type: departmentActionType.BATCH_UPDATE,
      payload: batch,
    };
  }

  public static batchUpdateSuccess(batch): Action {
    return {
      type: departmentActionType.BATCH_UPDATE_SUCCESS,
      payload: batch,
    };
  }

  public static batchUpdateFailed(err): Action {
    return {
      type: departmentActionType.BATCH_UPDATE_FAILED,
      payload: err,
    };
  }

  public static deactivate(id): Action {
    return {
      type: departmentActionType.DEACTIVATE,
      payload: id,
    };
  }

  public static deactivateSuccess(id): Action {
    return {
      type: departmentActionType.DEACTIVATE_SUCCESS,
      payload: id,
    };
  }

  public static deactivateFailed(id): Action {
    return {
      type: departmentActionType.DEACTIVATE_FAILED,
      payload: id,
    };
  }

  public static activate(id): Action {
    return {
      type: departmentActionType.ACTIVATE,
      payload: id,
    };
  }

  public static activateSuccess(id): Action {
    return {
      type: departmentActionType.ACTIVATE_SUCCESS,
      payload: id,
    };
  }

  public static activateFailed(id): Action {
    return {
      type: departmentActionType.ACTIVATE_FAILED,
      payload: id,
    };
  }
}
