import { UnsafeAction as Action } from '../../interfaces';
import { CustomFieldsModel } from './custom-fields.model';
import { Update } from '@ngrx/entity';

const ACTION_PREFIX = '[CustomFields]';

export const customFieldsActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  DELETE: `${ACTION_PREFIX} Delete`,
  DELETE_SUCCESS: `${ACTION_PREFIX} Delete Success`,
  DELETE_FAILED: `${ACTION_PREFIX} Delete Failed`,
};

export class CustomFieldsAction {
  static load(): Action {
    return {
      type: customFieldsActionType.LOAD,
    };
  }

  static loadSuccess(customFields): Action {
    return {
      type: customFieldsActionType.LOAD_SUCCESS,
      payload: customFields,
    };
  }

  static loadFailed(err): Action {
    return {
      type: customFieldsActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static add(): Action {
    return {
      type: customFieldsActionType.ADD,
    };
  }

  static addSuccess(data): Action {
    return {
      type: customFieldsActionType.ADD_SUCCESS,
      payload: data,
    };
  }

  static addFailed(err): Action {
    return {
      type: customFieldsActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(): Action {
    return {
      type: customFieldsActionType.UPDATE,
    };
  }

  static updateSuccess(data): Action {
    return {
      type: customFieldsActionType.UPDATE_SUCCESS,
      payload: data,
    };
  }

  static updateFailed(err): Action {
    return {
      type: customFieldsActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static fetch(id: any) {
    return {
      type: customFieldsActionType.FETCH,
      payload: id,
    };
  }

  static fetchSuccess(response) {
    return {
      type: customFieldsActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  static fetchFailed(id: string, err) {
    return {
      type: customFieldsActionType.FETCH_SUCCESS,
      payload: {
        id,
        err,
      },
    };
  }

  static delete(data: Update<CustomFieldsModel>): Action {
    return {
      type: customFieldsActionType.DELETE,
      payload: data,
    };
  }

  static deleteSuccess(id): Action {
    return {
      type: customFieldsActionType.DELETE_SUCCESS,
      payload: id,
    };
  }

  static deleteFailed(data: Update<CustomFieldsModel>, err): Action {
    return {
      type: customFieldsActionType.DELETE_FAILED,
      payload: { data, err },
    };
  }
}
