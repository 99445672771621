import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { Observable } from 'rxjs';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { arrayOf, normalize } from 'normalizr';
import { ShiftSchema } from '../../shared/schemas';
import { Action } from '@ngrx/store';
import { assignSchemaEntity } from '../../shared/assign';

@Injectable()
export class ShiftApi {
  private endpoint: string = 'shifts/';

  constructor(private gateway: ApiGateway) {}

  add(shiftData, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint, shiftData, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Shift', res)),
      map((data) => normalize(data, ShiftSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  update(shiftId, shiftData, dispatchStart?: Action): Observable<any> {
    return this.gateway.put(this.endpoint + shiftId, shiftData, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Shift', res)),
      map((data) => normalize(data, ShiftSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  batch(shifts): Observable<any> {
    return this.gateway.post(this.endpoint + 'batch', shifts, undefined).pipe(
      map((res) => reformatListResponse('Shift', res)),
      map((res) => normalize(res, arrayOf(ShiftSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  remove(shiftId, dispatchStart?: Action): Observable<any> {
    return this.gateway.delete(this.endpoint + shiftId, undefined, dispatchStart);
  }
}
