import { UntypedFormGroup } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SelectItem } from '@sb/ui';

export interface CustomFieldsModel {
  id: string;
  name: string;
  description: string;
  type: CustomFieldsType;
  validators?: any[];
  loading?: boolean;
  applies_to: CustomFieldsAppliesTo;
  applies_to_timesheet: boolean;
  applies_to_shift: boolean;
  applies_to_schedule: boolean;
  applies_to_user: boolean;
  width?: number;
  select_options?: string[];
  order: string;
}

export enum CustomFieldsType {
  TEXT = 'text',
  NUMERIC = 'numeric',
  DECIMAL = 'decimal',
  MONEY = 'money',
  DATE = 'date',
  BOOLEAN = 'boolean',
  SELECT = 'select',
  MULTI_SELECT = 'multi-select',
  URL = 'url',
}

export enum CustomFieldsAppliesTo {
  USER = 'User',
  TIMESHEET = 'Timesheet',
  SHIFT = 'Shift',
  SCHEDULE = 'Schedule',
}

export const customFieldsAppliesToOptions = [
  { value: CustomFieldsAppliesTo.SHIFT, label: _('Shifts') },
  { value: CustomFieldsAppliesTo.SCHEDULE, label: _('Schedules') },
  { value: CustomFieldsAppliesTo.TIMESHEET, label: _('Timesheets') },
];

export interface CustomFieldsGroup extends CustomFieldsModel {
  form: UntypedFormGroup;
  hasDeletedSelectValue: boolean;
  multiSelectOptions?: SelectItem[];
}

export interface HasCustomFields {
  custom_fields?: any;
}
