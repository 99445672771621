import { UnsafeAction as Action } from '../../interfaces';
import { LocationBaseModel } from './location.model';

const ACTION_PREFIX = '[Location]';

export const locationActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  DEACTIVATE: `${ACTION_PREFIX} Deactivate`,
  DEACTIVATE_SUCCESS: `${ACTION_PREFIX} Deactivate Success`,
  DEACTIVATE_FAILED: `${ACTION_PREFIX} Deactivate Failed`,

  ACTIVATE: `${ACTION_PREFIX} Activate`,
  ACTIVATE_SUCCESS: `${ACTION_PREFIX} Activate Success`,
  ACTIVATE_FAILED: `${ACTION_PREFIX} Activate Failed`,
};

export class LocationAction {
  public static load(): Action {
    return {
      type: locationActionType.LOAD,
    };
  }

  public static loadSuccess(locations): Action {
    return {
      type: locationActionType.LOAD_SUCCESS,
      payload: locations,
    };
  }

  public static loadFailed(err): Action {
    return {
      type: locationActionType.LOAD_FAILED,
      payload: err,
    };
  }

  public static add(locationData: LocationBaseModel): Action {
    return {
      type: locationActionType.ADD,
      payload: locationData,
    };
  }

  public static addSuccess(locationResponse): Action {
    return {
      type: locationActionType.ADD_SUCCESS,
      payload: locationResponse,
    };
  }

  public static addFailed(err): Action {
    return {
      type: locationActionType.ADD_FAILED,
      payload: err,
    };
  }

  public static update(locationId, locationData): Action {
    return {
      type: locationActionType.UPDATE,
      payload: locationData,
    };
  }

  public static updateSuccess(locationResponse): Action {
    return {
      type: locationActionType.UPDATE_SUCCESS,
      payload: locationResponse,
    };
  }

  public static updateFailed(err): Action {
    return {
      type: locationActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  public static fetch(id: any) {
    return {
      type: locationActionType.FETCH,
      payload: id,
    };
  }

  public static fetchSuccess(response) {
    return {
      type: locationActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  public static fetchFailed(id: string, err) {
    return {
      type: locationActionType.FETCH_SUCCESS,
      payload: {
        id,
        err,
      },
    };
  }

  public static deactivate(id: string): Action {
    return {
      type: locationActionType.DEACTIVATE,
      payload: id,
    };
  }

  public static deactivateSuccess(id: string): Action {
    return {
      type: locationActionType.DEACTIVATE_SUCCESS,
      payload: id,
    };
  }

  public static deactivateFailed(id: string): Action {
    return {
      type: locationActionType.DEACTIVATE_FAILED,
      payload: id,
    };
  }

  public static activate(id: string): Action {
    return {
      type: locationActionType.ACTIVATE,
      payload: id,
    };
  }

  public static activateSuccess(id: string): Action {
    return {
      type: locationActionType.ACTIVATE_SUCCESS,
      payload: id,
    };
  }

  public static activateFailed(id: string): Action {
    return {
      type: locationActionType.ACTIVATE_FAILED,
      payload: id,
    };
  }
}
