import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[Shift]';

export const shiftActionType = {
  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,
  REMOVE_FAILED: `${ACTION_PREFIX} Remove Failed`,
};

export class ShiftAction {
  static add(shiftData): Action {
    return {
      type: shiftActionType.ADD,
      payload: shiftData,
    };
  }

  static addSuccess(shiftResponse): Action {
    return {
      type: shiftActionType.ADD_SUCCESS,
      payload: shiftResponse,
    };
  }

  static addFailed(err): Action {
    return {
      type: shiftActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(shiftData): Action {
    return {
      type: shiftActionType.UPDATE,
      payload: shiftData,
    };
  }

  static updateSuccess(shiftResponse): Action {
    return {
      type: shiftActionType.UPDATE_SUCCESS,
      payload: shiftResponse,
    };
  }

  static updateFailed(err): Action {
    return {
      type: shiftActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static remove(id): Action {
    return {
      type: shiftActionType.REMOVE,
      payload: id,
    };
  }

  static removeSuccess(id): Action {
    return {
      type: shiftActionType.REMOVE_SUCCESS,
      payload: id,
    };
  }

  static removeFailed(id, err): Action {
    return {
      type: shiftActionType.REMOVE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }
}
