import { Injectable } from '@angular/core';
import { sortByDefault } from '@app/reducers/helpers/default-sorting.helper';
import { Dictionary } from '@ngrx/entity';
import { compose, Store } from '@ngrx/store';
import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import u from 'updeep';

import { getAuthenticatedDepartmentIds } from '../../auth/auth.service';
import {
  getPermissionState,
  hasPermission,
  PermissionCheck,
  permissionDepartments,
} from '../../auth/permission.helper';
import { AppState } from '../../index';
import { getSelectedDepartmentIds } from '../../selected-departments/selected-departments.service';
import { filterDeleted, mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { employeeInDepartment, getActiveEmployees } from '../employee/employee.service';
import { getActiveShiftsGroupedByDepartment } from '../shift/shift.service';
import { getTeamEntities } from '../team/team.service';
import { DepartmentAction } from './department.action';
import { DepartmentApi } from './department.api';
import { DepartmentModel, DepartmentState } from './department.model';

export interface DeactivateResponse {
  hasTargetDepartmentId?: boolean;
  hasTargetTeamId?: boolean;
}

@Injectable()
export class DepartmentService {
  public deactivateFields: DeactivateResponse = {};

  public constructor(
    private store: Store<AppState>,
    private api: DepartmentApi,
  ) {}
  public fetch(id) {
    this.store.dispatch(DepartmentAction.fetch(id));

    return this.api.fetch(id).pipe(
      map((response) => {
        this.store.dispatch(DepartmentAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(DepartmentAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public save(data) {
    if (data.id) {
      return this.update(data.id, data);
    }

    data = u.omit('id', data);

    return this.add(data);
  }

  public add(data): Observable<any> {
    this.store.dispatch(DepartmentAction.add(data));

    return this.api.add(data).pipe(
      map((response) => {
        this.store.dispatch(DepartmentAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(DepartmentAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, data, ignoreMetaMessage?: boolean) {
    this.store.dispatch(DepartmentAction.update(id, data));

    return this.api.update(id, data, ignoreMetaMessage).pipe(
      map((response) => {
        this.store.dispatch(DepartmentAction.updateSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(DepartmentAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public validateDeactivate(id: string) {
    return this.api.validateDeactivate(id).pipe(catchError((response) => observableThrowError(response)));
  }

  public deactivate(id: string, targetDepartmentId?: string, targetTeamId?: string) {
    this.store.dispatch(DepartmentAction.deactivate(id));

    return this.api.deactivate(id, { targetDepartmentId: targetDepartmentId, targetTeamId: targetTeamId }).pipe(
      tap((response) => {
        this.store.dispatch(DepartmentAction.deactivateSuccess(id));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(DepartmentAction.deactivateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public activate(id: string) {
    this.store.dispatch(DepartmentAction.activate(id));

    return this.api.activate(id).pipe(
      tap((response) => {
        this.store.dispatch(DepartmentAction.activateSuccess(id));
      }),
      catchError((response) => {
        this.store.dispatch(DepartmentAction.activateFailed(id));
        return observableThrowError(response);
      }),
    );
  }

  public batchUpdate(batch: any, ignoreMetaMessage?: boolean) {
    this.store.dispatch(DepartmentAction.batchUpdate(batch));

    return this.api.batchUpdate({ Department: batch }, ignoreMetaMessage).pipe(
      map((response) => {
        this.store.dispatch(DepartmentAction.batchUpdateSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(DepartmentAction.batchUpdateFailed(response));
        return observableThrowError(response);
      }),
    );
  }
}

export const sortDepartments = (departments: DepartmentModel[]) => sortByDefault<DepartmentModel>(departments);

export const mapAndSortDepartments = mapAndSortEntities(sortDepartments);

export const getDepartmentState = (state: AppState): DepartmentState => state.orm.departments;

export const getDepartmentIds = compose((state) => state.items, getDepartmentState);
export const getDepartmentEntities = createSelector(
  getDepartmentState,
  getActiveShiftsGroupedByDepartment,
  (departmentState, activeShifts) => {
    const departmentEntities = departmentState.itemsById;
    return mapValues(departmentEntities, (department) => {
      if (department.default_clock_shift) {
        return department;
      }

      const defaultShift =
        activeShifts[department.id] && activeShifts[department.id].length > 0
          ? activeShifts[department.id][0].id
          : null;

      return {
        ...department,
        default_clock_shift: defaultShift,
      };
    });
  },
);

export const getDepartments = createSelector(getDepartmentIds, getDepartmentEntities, mapAndSortDepartments);
export const getSelectedDepartments = createSelector(
  getSelectedDepartmentIds,
  getDepartmentEntities,
  mapAndSortDepartments,
);

export const getSelectedPermissionDepartments = (check: PermissionCheck) =>
  createSelector(getPermissionState, (permissionState) => {
    check.departments = [];
    return permissionDepartments(check, permissionState);
  });
export const getSelectedDepartmentsSortedIds = createSelector(
  getSelectedDepartments,
  (selectedDepartments) => selectedDepartments && selectedDepartments.map((department) => department['id']),
);

export const getActiveDepartments = createSelector(getDepartments, (departments: DepartmentModel[]) =>
  filterDeleted(departments),
);

export const getActiveDepartmentsForPermission = (permissionCheck: PermissionCheck) =>
  createSelector(getActiveDepartments, getPermissionState, (departments: DepartmentModel[], permissionState) =>
    departments.filter((department) => {
      permissionCheck.departments = department.id;
      return hasPermission(permissionCheck, permissionState);
    }),
  );

export const getActiveDepartmentAmount = createSelector(
  getActiveDepartments,
  (departments: DepartmentModel[]) => departments.length,
);

export const getDepartmentsById = (ids: string[]) =>
  createSelector(getDepartmentEntities, (entities) => mapAndSortDepartments(ids, entities));

export const getDepartment = (id: string) =>
  createSelector(getDepartmentEntities, (entities) => mapEntity(id, entities));

export const groupDepartmentsByLocation = (departments) =>
  <Dictionary<DepartmentModel[]>>groupBy(departments, 'location_id');
export const getAllDepartmentsGroupedByLocation = createSelector(getDepartments, groupDepartmentsByLocation);
export const getActiveDepartmentsGroupedByLocation = createSelector(getActiveDepartments, groupDepartmentsByLocation);
export const getActiveDepartmentsGroupedByLocationForPermission = (permissionCheck: PermissionCheck) =>
  createSelector(getActiveDepartmentsForPermission(permissionCheck), (departments) =>
    groupDepartmentsByLocation(departments),
  );

export const getAuthenticatedDepartments = createSelector(
  getDepartmentEntities,
  getAuthenticatedDepartmentIds,
  (departmentEntities, selectedDepartmentIds) =>
    filterDeleted(mapAndSortDepartments(selectedDepartmentIds, departmentEntities)),
);

export const getDepartmentIdsWithPlanPermissions = createSelector(
  getSelectedDepartmentIds,
  getPermissionState,
  (selectedDepartmentIds, permissionState) =>
    selectedDepartmentIds.filter((selectedDepartment) => {
      const permissionCheck: PermissionCheck = {
        permissions: ['Create roster', 'Edit roster', 'Delete roster'],
        userId: 'me',
        departments: selectedDepartment,
      };

      return hasPermission(permissionCheck, permissionState);
    }),
);

export const getEmployeeIdsFromDepartments = (departmentIds: string[]) =>
  createSelector(
    getActiveEmployees,
    getTeamEntities,
    getPermissionState,
    (activeEmployees, teamEntities, permissionState) => {
      departmentIds = departmentIds.filter((departmentId) => {
        const permissionCheck: PermissionCheck = {
          permissions: ['Create roster', 'Edit roster', 'Delete roster'],
          userId: 'me',
          departments: departmentId,
        };

        return hasPermission(permissionCheck, permissionState);
      });
      return activeEmployees.filter(employeeInDepartment(departmentIds, teamEntities)).map((employee) => employee.id);
    },
  );

export const getEmployeeIdsFromSelectedDepartments = createSelector(
  getActiveEmployees,
  getDepartmentIdsWithPlanPermissions,
  getTeamEntities,
  (activeEmployees, selectedDepartmentIds, teamEntities) =>
    activeEmployees.filter(employeeInDepartment(selectedDepartmentIds, teamEntities)).map((employee) => employee.id),
);
