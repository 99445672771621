import { customFieldsAdapter, CustomFieldsState } from './custom-fields.state';
import { AppState } from '../../index';
import { createSelector } from 'reselect';
import { CustomFieldsAppliesTo, CustomFieldsModel, CustomFieldsType } from './custom-fields.model';
import { ValidationService } from '../../../forms/validation.service';
import { convertArrayToObject } from '../../shared/entity.helper';
import isArray from 'lodash-es/isArray';

export const getCustomFieldsState = (appState: AppState): CustomFieldsState => appState.orm.customFields;
export const { selectAll, selectEntities, selectIds, selectTotal } =
  customFieldsAdapter.getSelectors(getCustomFieldsState);

export const getCustomFieldById = (id: number) => createSelector(selectEntities, (customFields) => customFields[id]);

export const getCustomFields = createSelector(selectAll, (customFields) =>
  customFields.map((customField) => ({
    ...customField,
    validators: enhanceCustomFields(customField.type),
  }))
);

export const getCustomFieldsByAppliesTo = (appliesTo: CustomFieldsAppliesTo | CustomFieldsAppliesTo[]) =>
  createSelector(getCustomFields, (customFields: CustomFieldsModel[]) =>
    customFields
      .filter((customField) => {
        if (!isArray(appliesTo)) {
          appliesTo = [appliesTo];
        }

        return appliesTo.some((applies) => {
          switch (applies) {
            case CustomFieldsAppliesTo.SCHEDULE:
              return customField.applies_to_schedule;
            case CustomFieldsAppliesTo.TIMESHEET:
              return customField.applies_to_timesheet;
            case CustomFieldsAppliesTo.SHIFT:
              return customField.applies_to_shift;
            case CustomFieldsAppliesTo.USER:
              return customField.applies_to_user;
            default:
              return false;
          }
        });
      })
      .sort((a, b) => {
        const orderOfA = parseInt(a.order);
        const orderOfB = parseInt(b.order);

        // If the order of the both custom fields is equal, we sort them by their ID.
        if (orderOfA !== orderOfB) {
          return orderOfA - orderOfB;
        }

        let idOfA = parseInt(a.id);
        let idOfB = parseInt(b.id);

        return idOfA - idOfB;
      })
  );

export const getCustomFieldEntities = createSelector(getCustomFields, (customFields) =>
  convertArrayToObject('id', customFields)
);

const enhanceCustomFields = (fieldType: CustomFieldsType) => {
  switch (fieldType) {
    case CustomFieldsType.DATE:
      return [ValidationService.dateValidator];
    case CustomFieldsType.DECIMAL:
      return [ValidationService.decimalValidator];
    case CustomFieldsType.NUMERIC:
      return [ValidationService.numberValidatorAllowNegative];
    case CustomFieldsType.MONEY:
      return [ValidationService.decimalValidator];
    default:
      return [];
  }
};
