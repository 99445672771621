import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CustomFieldsModel } from './custom-fields.model';
import clamp from 'lodash-es/clamp';

export interface CustomFieldsState extends EntityState<CustomFieldsModel> {
  isLoading?: boolean;
  error?: any;
}

export const customFieldsAdapter: EntityAdapter<CustomFieldsModel> = createEntityAdapter<CustomFieldsModel>({
  sortComparer: (a, b) => {
    const orderA = parseInt(a.order ?? '1', 10);
    const orderB = parseInt(b.order ?? '1', 10);

    if (orderA === orderB) {
      return a.id.localeCompare(b.id);
    }
    return clamp(orderA - orderB, -1, 1);
  },
});

export const initialState: CustomFieldsState = customFieldsAdapter.getInitialState({
  isLoading: false,
  error: null,
});
