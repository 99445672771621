import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { SelectItem } from '@sb/ui';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import omit from 'lodash-es/omit';
import { BehaviorSubject, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { getArrayDifference } from '../../../shared/array.helper';
import { AppState } from '../../index';
import { CustomFieldsAction } from './custom-fields.action';
import { CustomFieldsApi } from './custom-fields.api';
import { CustomFieldsAppliesTo, CustomFieldsModel, CustomFieldsType } from './custom-fields.model';
import { getCustomFields } from './custom-fields.selectors';

@Injectable()
export class CustomFieldsService {
  private customFields$ = new BehaviorSubject<CustomFieldsModel[]>([]);
  public customFieldsState = new BehaviorSubject({});

  private dataSubs = new Subscription();

  public constructor(
    private api: CustomFieldsApi,
    private store: Store<AppState>,
  ) {
    this.dataSubs.add(
      this.store.select(getCustomFields).subscribe((customFields) => {
        this.customFields$.next(customFields);
      }),
    );
  }

  public filterObsoleteCustomFields(modelWithCustomFields: Dictionary<string>, appliesTo: CustomFieldsAppliesTo) {
    const customFieldsModel = { ...modelWithCustomFields };
    if (!this.customFields$.value || this.customFields$.value?.length === 0) {
      return {};
    }

    let checkType = '';
    switch (appliesTo) {
      case CustomFieldsAppliesTo.SCHEDULE: {
        checkType = 'applies_to_schedule';
        break;
      }
      default:
        return {};
    }

    const customFields = this.customFields$.value
      .filter((customField: CustomFieldsModel) => customField[checkType])
      .map((customField) => customField.id.toString());

    Object.keys(customFieldsModel).forEach((key) => customFields.includes(key) || delete customFieldsModel[key]);
    return customFieldsModel;
  }

  public load() {
    return this.api.getCustomFields(CustomFieldsAction.load()).pipe(
      tap((data) => {
        this.store.dispatch(CustomFieldsAction.loadSuccess(data));
      }),
      catchError((response) => {
        this.store.dispatch(CustomFieldsAction.loadFailed(response));
        return throwError(response);
      }),
    );
  }

  public save(data) {
    return this.api.save(data, CustomFieldsAction.add()).pipe(
      tap((response) => {
        this.store.dispatch(CustomFieldsAction.addSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(CustomFieldsAction.addFailed(response));
        return throwError(response);
      }),
    );
  }

  public update(id: number, data) {
    return this.api.update(id, data, CustomFieldsAction.update()).pipe(
      tap((response) => {
        this.store.dispatch(CustomFieldsAction.updateSuccess({ id, changes: omit(response, ['id']) }));
      }),
      catchError((response) => {
        this.store.dispatch(CustomFieldsAction.updateFailed(response));
        return throwError(response);
      }),
    );
  }

  public delete(id: number) {
    return this.api.delete(id, CustomFieldsAction.delete({ id, changes: { loading: true } })).pipe(
      tap(() => {
        this.store.dispatch(CustomFieldsAction.deleteSuccess(id));
      }),
      catchError((response) => {
        this.store.dispatch(CustomFieldsAction.deleteFailed({ id, changes: { loading: false } }, response));
        return throwError(response);
      }),
    );
  }

  public createCustomFieldMapping(customFields: CustomFieldsModel[], customFieldsData, fieldsWithBlur = []) {
    const customFieldsDataMapping = this.getCustomFieldMappingFromData(customFieldsData);

    const mappedCustomFields = [];
    const formArray = new UntypedFormArray([]);
    customFields.forEach((customField) => {
      const hasBlur = fieldsWithBlur.includes(customField.type);
      const validators = {
        validators: customField.validators ? customField.validators : [],
      };

      if (hasBlur) {
        validators['updateOn'] = 'blur';
      }

      const form = new UntypedFormGroup({
        id: new UntypedFormControl(customField.id),
        value: new UntypedFormControl(
          customFieldsDataMapping && customFieldsDataMapping[customField.id]
            ? customFieldsDataMapping[customField.id]
            : this.getDefaultForFormField(customField.type),
          validators,
        ),
      });

      let hasDeletedSelectValue = false;
      if (customField.type === CustomFieldsType.SELECT && !!form.get('value').value) {
        hasDeletedSelectValue = !customField.select_options.includes(form.get('value').value);
      }

      let multiSelectOptions: SelectItem[] = [];

      if (customField.type === CustomFieldsType.MULTI_SELECT) {
        const nonValidValue = isEmpty(form.get('value')?.value) || !isArray(form.get('value')?.value);
        const optionsNotInMultiSelect = getArrayDifference(
          nonValidValue ? [] : form.get('value')?.value,
          customField.select_options,
        );

        multiSelectOptions = customField.select_options.map((option) => ({
          text: option,
          value: option,
        }));

        multiSelectOptions.push(
          ...optionsNotInMultiSelect.map((option) => ({
            text: option,
            value: option,
          })),
        );
      }

      mappedCustomFields.push({
        ...customField,
        multiSelectOptions,
        form,
        hasDeletedSelectValue,
      });
      formArray.push(form);
    });

    return {
      mappedCustomFields,
      formArray,
    };
  }

  public mapFormDataToRequestData = (customFieldData: AbstractControl) => {
    if (!customFieldData?.value) {
      return {};
    }

    const customFields = {};
    const customFieldFormValues = isArray(customFieldData.value)
      ? customFieldData.value.filter((customField) => customField.value !== null && customField.value !== undefined)
      : [];

    customFieldFormValues.forEach((customField) => {
      customFields[customField.id] = customField.value ?? this.getDefaultForFormField(customField.type);
    });

    return customFields;
  };

  private getCustomFieldMappingFromData = (customFields: any) => {
    if (!customFields) {
      return {};
    }
    return customFields;
  };

  private getDefaultForFormField = (type: string) => {
    switch (type) {
      case CustomFieldsType.MONEY:
      case CustomFieldsType.DECIMAL:
      case CustomFieldsType.NUMERIC:
        return 0;
      case CustomFieldsType.BOOLEAN:
        return false;
      case CustomFieldsType.MULTI_SELECT:
        return [];
      default:
        return '';
    }
  };
}
