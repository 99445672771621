import { Injectable } from '@angular/core';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction } from '../../interfaces';
import { CustomFieldsModel } from './custom-fields.model';
import { map } from 'rxjs/operators';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';

@Injectable()
export class CustomFieldsApi {
  private endpoint = 'custom_fields/';

  constructor(private gateway: ApiGateway) {}

  public getCustomFields(dispatchStart?: UnsafeAction) {
    return this.gateway
      .get(this.endpoint, null, dispatchStart)
      .pipe(map((res) => reformatListResponse('CustomField', res)));
  }

  public save(data: CustomFieldsModel, dispatchStart?: UnsafeAction) {
    return this.gateway
      .post(this.endpoint, data, null, dispatchStart)
      .pipe(map((res) => reformatEntityResponse('CustomField', res)));
  }

  public update(id, data: CustomFieldsModel, dispatchStart?: UnsafeAction) {
    return this.gateway
      .put(this.endpoint + id, data, null, dispatchStart)
      .pipe(map((res) => reformatEntityResponse('CustomField', res)));
  }

  public delete(id, dispatchStart?: UnsafeAction) {
    return this.gateway.delete(this.endpoint + id, null, dispatchStart);
  }
}
